<template>
  <div class="req">
    <v-btn class="mb-5" size="x-small" v-if="req_id !== 'new'" color="orange" @click="$router.push('/reqs')">Back to Requisitions</v-btn>

    <v-card max-width="800" class="mx-auto pb-10">
      <v-card-item style="color:gray" :title="req_id === 'new' ? 'New Requisition Form' : 'Edit Requisition Form ' + req_id"></v-card-item>
      <ReqForm :reqid="req_id"/>
    </v-card>

  </div>
</template>


<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import ReqForm from '../components/ReqForm.vue';

export default {
  name: 'ReqView',
  components: {ReqForm},
  data(){
    return {
      req_id: this.$route.params['reqid'],
    }    
  },
  watch: {
    '$route'(to, from) {
      // Check if navigating to 'new' requisition from an 'edit' route
      if (to.path === '/req/new' && from.path.startsWith('/req/')) {
        window.location.reload();
      }
    }
  },
  mounted(){
  },
  computed: {
    ...mapGetters({
      user: 'user',
      db: 'db'
    }),
  },
  methods:{

  }
}
</script>

<style>
.req {
    height: 100vh;
    overflow-y: auto;
    padding: 20px 20px 100px 20px;
}

</style>