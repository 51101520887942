import axios from 'axios'

export const emailMixin = {
  data(){
    return{
      url: 'https://us-central1-riverhawk-req.cloudfunctions.net/sendEmail',
      from: 'apps@riverhawkindustrialsupply.com',
      purchaser_contacts: 'christopher.w.finlayson@gmail.com;sfinlayson@riverhawkindustrialsupply.com',
      link: 'http://localhost:8080/req/'
    }
  },
  methods:{
    newReqEmail(email){
      var subject = 'New Requisition - Job#: ' + email.job_number
      var html = 'New Requisition has been submitted.<br><br>'
      html += 'Submitted By Name: ' + email.name + '<br>'
      html += 'Submitted By Email: ' + email.email + '<br>'
      html += 'Submitted By Phone Number: ' + email.phone + '<br>'
      html += 'Job Number: ' + email.job_number + '<br>'
      html += 'Ship To Address: ' + email.ship_to_address + '<br>'
      html += 'Date Needed: ' + email.date_needed + '<br>'
      html += 'Items: ' + email.items.map(item => `${item.qty} ${item.uom} ${item.description}`).join(', ') + '<br>'
      html += 'View Requisition: <a href="' + this.link + email.key + '">' + this.link + email.key + ' </a>'
      email.to = this.purchaser_contacts

      var encodedSubject = encodeURIComponent(subject);
      var encodedHtml = encodeURIComponent(html);
      var url = this.url + '?to=' + email.to + '&from=' + this.from + '&subject=' + encodedSubject + '&html=' + encodedHtml

      axios.post(url, {'Access-Control-Allow-Origin' : '*','Access-Control-Allow-Methods': 'POST'})
      .then(function (response) {
        if(response.data.success){
          console.log(response.data.success.message)
        }else{
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }
}