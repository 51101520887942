import { createRouter, createWebHistory } from 'vue-router'
import ReqView from '../views/ReqView.vue'
import ReqsView from '../views/ReqsView.vue'
import CatalogView from '../views/CatalogView.vue'

const routes = [
  {
    path: '/',
    redirect: '/req/new' // Redirect from root to '/req/new'
  },  
  {
    path: '/req/:reqid',
    name: 'req',
    component: ReqView
  },
  {
    path: '/reqs',
    name: 'reqs',
    component: ReqsView
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: CatalogView
  }  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
