<template>

  <div class="form">
    <v-form ref="reqForm" @submit.prevent="reqid == 'new' ? submitForm() : saveEditForm()">
      
      <v-btn v-if="reqid !== 'new'" style="background:#ff7214;color:white;height:20px;width:110px;font-size:10px;margin-bottom:20px" @click="exportReq()">EXPORT TO EXCEL</v-btn>

      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.name"
              label="Name"
              :rules="nameRules"
              density="compact"
              hide-details="auto"
              variant="solo"
            ></v-text-field>
          </v-col>
      
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.email"
              label="Email"
              :rules="emailRules"
              density="compact"
              hide-details="auto"
              variant="solo"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.phone"
              label="Phone Number"
              :rules="phoneRules"
              v-mask="'(###) ###-####'"
              density="compact"
              hide-details="auto"
              variant="solo"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.job_number"
              label="Job Number"
              :rules="jobNumberRules"
              density="compact"
              hide-details="auto"
              variant="solo"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.ship_to_address"
              label="Ship To Address"
              :rules="shipToAddressRules"
              density="compact"
              hide-details="auto"
              variant="solo"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <VueDatePicker v-model="form.date_needed" :enable-time-picker="false" :month-change-on-scroll="false" auto-apply>
                <template #trigger>                    
                  <v-text-field
                    v-model="formatDateNeeded"
                    label="Date Needed"
                    density="compact"
                    hide-details="auto"
                    variant="solo"
                    readonly
                    prepend-inner-icon="mdi-calendar"
                  ></v-text-field>
                </template>
            </VueDatePicker>
          </v-col>

        </v-row>
        <v-row>

        <v-divider class="mb-5 ml-2 mr-2"></v-divider>

          <v-card v-for="(item, index) in form.items" :key="index" width="100%" class="mx-auto pa-2 ml-3 mr-3 mb-5" elevation="2">
            
            <v-row class="align-center" no-gutters>
              <v-col style="color:gray">Item {{index + 1}}</v-col>
              <v-col cols="auto">
                <v-btn v-if="form.items.length > 1" icon color="orange" size="x-small" @click="removeItem(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>            
            </v-row>

            <v-row>
              <v-col cols="12" md="2" sm="6">
                <v-text-field
                  v-model="item.qty"
                  label="Qty"
                  type="number"
                  v-model.number="quantity"
                  hide-details="auto"
                  variant="solo"
                  density="compact"
                  min="1"
                ></v-text-field>                  
              </v-col>

              <v-col cols="12" md="2" sm="6">
                <v-select
                  v-model="item.uom"
                  :items="['BX', 'CS', 'DZ', 'EA', 'LB', 'PK', 'PL', 'PR', 'RL']"
                  label="UOM"
                  hide-details="auto"
                  density="compact"
                  variant="solo"
                ></v-select>
              </v-col>

              <v-col cols="12" md="8" sm="12">
                <v-textarea
                  v-model="item.description"
                  :label="`Describe Item or Search Catalog`"
                  hide-details="auto"
                  variant="solo"
                  density="compact"
                  rows="1"
                  auto-grow
                  class="pb-2"
                  v-if="!item.from_catalog"
                ></v-textarea>
                <div style="line-height:100%;font-size:14px;margin-top:5px;margin-bottom:10px;border:1px solid lightgray;padding:10px;border-radius:2px" v-if="item.from_catalog">{{item.description}}</div>
                <v-btn size="x-small" color="gray" @click="searchCatalog(index)" style="float:right" class="mb-2" v-if="!item.from_catalog">Search Catalog</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-btn size="x-small" color="orange" class="my-2" @click="addItem">Add Item</v-btn>

        <v-divider></v-divider>

        <v-select
          v-model="form.status"
          :items="['open','in process','completed','cancelled']"
          label="Status"
          density="compact"
          hide-details="auto"
          variant="solo"
          class="mt-4 mb-4"
          v-if="reqid !== 'new'"
        ></v-select>

        <v-divider></v-divider>

        <div class="d-flex justify-end">
            <v-btn type="button" color="gray" class="mt-2 mr-2" @click="confirmCancel">
                Cancel
            </v-btn>
            <v-btn type="submit" color="orange" class="mt-2" :disabled="!formIsValid() || isSaving">
                {{ reqid === 'new' ? 'Submit' : 'Save' }}
            </v-btn>
        </div>

      </v-container>
    </v-form>
    <v-alert v-if="successMessage" text="Req saved successfully." color="success" icon="$success" class="mt-2 top-alert"></v-alert>
  </div>


  <AddFromCatalogPopup v-if="index_for_catalog_popup !== false"  @close="index_for_catalog_popup = false" @add="addItemFromCatalog" />
</template>

<script>
import firebase from 'firebase/compat/app';
import AddFromCatalogPopup from '@/components/AddFromCatalogPopup.vue'
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { emailMixin } from '../mixins/emailMixin.js'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Moment from 'moment'
import XLSX from 'xlsx'

export default {
  name: 'ReqForm',
  components: {AddFromCatalogPopup,VueDatePicker},
  mixins: [emailMixin],
  props:{
    reqid: String
  },  
  directives: { mask },
  data (){
    return {
      form: {
          name: '',
          email: '',
          phone: '',
          job_number: '',
          ship_to_address: '',
          date_needed: '',
          items: [{qty: 1, uom: 'EA', description: ''},{qty: 1, uom: 'EA', description: ''}],
      },
      index_for_catalog_popup: false,
      successMessage: false,
      originalForm: null,
      isSaving: false,
      nameRules: [
        value => {
          if (value?.length > 0) return true;
          return 'Please provide name';
        },
      ],
      emailRules: [
        value => {
          if (!value) return 'Please provide an email address';
          if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)) {
            return 'Please enter a valid email address';
          }
          return true;
        },
      ],
      phoneRules: [
        value => {
        if (!value) return 'Please provide a phone number';
        const onlyNums = value.replace(/\D/g, '');
        if (onlyNums.length !== 10) {
          return 'Please enter a valid 10-digit phone number';
        }
        return true; // indicates valid input
        },
      ],
      jobNumberRules: [
        value => {
          if (value?.length > 0) return true;
          return 'Please provide job number.';
        },
      ],
      shipToAddressRules: [
        value => {
          if (value?.length > 0) return true;
          return 'Please provide ship to address.';
        },
      ],
      itemRules: [
        value => {
          if (value?.length > 0) return true;
          return 'Please provide item needed';
        },
      ],    
    }
  },
  beforeMount(){
    var self = this
    if(self.reqid !== 'new'){
      firebase.database().ref('reqs').child(self.reqid).once('value', function(req_snapshot) {
        self.form = req_snapshot.val()
        self.originalForm = JSON.parse(JSON.stringify(self.form))
      })
    }
  },  
  computed: {
    ...mapGetters({
      user: 'user',
      db: 'db'
    }),
    formatDateNeeded(){
      return this.form.date_needed ? Moment(this.form.date_needed).format('MM/DD/YYYY') : ''
    },
    formIsEdited() {
      return JSON.stringify(this.form) !== JSON.stringify(this.originalForm); // Compare current form state with original state
    }
  },
  methods: {
    exportReq(){
        var export_arr = []
        export_arr.push(['Req ID:', this.reqid])
        export_arr.push(['Job Number:', this.form.job_number])
        export_arr.push(['Date Items Needed:', this.form.date_needed])
        export_arr.push(['Ship to Address:', this.form.ship_to_address])
        export_arr.push(['Submitted By Name:', this.form.name])
        export_arr.push(['Submitted By Email:', this.form.email])
        export_arr.push(['Submitted By Phone:', this.form.phone])
        export_arr.push([''])
        export_arr.push(['UOM','QTY','RIVERHAWK PART #','DESCRIPTION'])

        for(var i = 0; i < this.form.items.length; i++){
          var item = this.form.items[i]
          export_arr.push([item.uom, item.qty, item.riverhawk_part_number ? item.riverhawk_part_number : '', item.description])
        }

        var wscols = [
            {wch:20},
            {wch:30},
            {wch:30},
            {wch:100}
        ];

        var worksheet = XLSX.utils.json_to_sheet(export_arr, {'skipHeader': true });
        worksheet['!cols'] = wscols;
        var workbook = XLSX.utils.book_new()
        var export_date = Date.now()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, 'Requisition ' + this.reqid + ' ' + export_date + '.xlsx')

    },    
    formIsValid(){
      if (this.reqid === 'new') {
        return this.validateFormFields();
      } else {
        return this.formIsEdited && this.validateFormFields();
      }
    },
    validateFormFields() {
      return this.nameRules.every(rule => rule(this.form.name) === true) &&
             this.emailRules.every(rule => rule(this.form.email) === true) &&
             this.phoneRules.every(rule => rule(this.form.phone) === true) &&
             this.jobNumberRules.every(rule => rule(this.form.job_number) === true) &&
             this.shipToAddressRules.every(rule => rule(this.form.ship_to_address) === true) &&
             this.form.date_needed
    },    
    confirmCancel() {
      if (window.confirm("Are you sure you want to cancel?")) {
        this.form = {
          name: '',
          email: '',
          phone: '',
          job_number: '',
          ship_to_address: '',
          date_needed: '',
          items: [{qty: 1, uom: 'EA', description: ''},{qty: 1, uom: 'EA', description: ''}]
        };
        if (this.$refs.reqForm) {
          this.$refs.reqForm.reset();
        }
      }
    },    
    addItem() {
      this.form.items.push({qty: 1, uom: 'EA', description: ''});  // Add a new item field
    },
    addItemFromCatalog(item){
      this.form.items[this.index_for_catalog_popup].description = item.description
      this.form.items[this.index_for_catalog_popup].from_catalog = true
      this.form.items[this.index_for_catalog_popup].riverhawk_part_number = item.riverhawk_part_number
      this.index_for_catalog_popup = false
    },
    searchCatalog(item_index){
      this.index_for_catalog_popup = item_index
    },
    removeItem(index) {
      this.form.items.splice(index, 1);
    },
    async generateNextKey() {
      const ref = firebase.database().ref('reqs');
      const snapshot = await ref.once('value');
      const count = snapshot.numChildren() + 1; // Get the current count of requests and add 1
      return String(count).padStart(5, '0'); // Pad the count with zeros to make it five digits
    }, 
    async submitForm() {
      const self = this;
      self.form.date_needed = Moment(this.form.date_needed).format('MM/DD/YYYY')
      self.form.date_created = Date.now()
      self.form.status = 'open'
      try {
        const key = await self.generateNextKey();
        const reqRef = firebase.database().ref('reqs/' + key);
        await reqRef.set(self.form);
        self.form.key = key
        self.newReqEmail(self.form);
        self.$refs.reqForm.reset();
        self.form.date_needed = ''
        self.form.items = [{qty: 1, uom: 'EA', description: ''},{qty: 1, uom: 'EA', description: ''}];
        self.successMessage = true;
        setTimeout(() => {
          self.successMessage = false;
        }, 3000);
      } catch (error) {
        console.error('Error submitting the request:', error);
      }
    },
    saveEditForm(){
      const self = this;
      self.isSaving = true;
      self.form.date_modified = Date.now(); // Set the modification date
      self.form.date_needed = Moment(this.form.date_needed).format('MM/DD/YYYY')
      let updates = {};
      let historyUpdate = {};

      const fieldsToUpdate = ['name', 'email', 'phone', 'job_number', 'ship_to_address', 'date_needed', 'items', 'status','date_modified'];
      fieldsToUpdate.forEach(field => {
        if (JSON.stringify(self.form[field]) !== JSON.stringify(self.originalForm[field])) {
          updates[field] = self.form[field];
        }
      });

      if (Object.keys(updates).length > 0) {
        historyUpdate[`reqs/${self.reqid}/history/${self.form.date_modified}`] = self.originalForm;
        firebase.database().ref().update(historyUpdate).then(() => {
          firebase.database().ref('reqs/' + self.reqid).update(updates).then(() => {
            self.originalForm = JSON.parse(JSON.stringify(self.form)); // Update originalForm to the new state
            self.successMessage = true;
            self.isSaving = false; // Re-enable the button
            setTimeout(() => {
              self.successMessage = false;
            }, 3000);
          }).catch(error => {
            console.error('Error updating the request:', error);
            self.isSaving = false; // Re-enable the button in case of error
          });
        });
      } else {
        self.isSaving = false;
      }
    }
  }
}
</script>
<style>
.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #ff7214;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #ff7214;
    --dp-primary-disabled-color: #6bacea;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

.top-alert {
    position: fixed;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* Ensure it's above other elements */
    width: 100%; /* or any specific width */
    max-width: 600px; /* optional, for better display on wide screens */
}

</style>