<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw" style="min-height: 90vh;max-height:90vh">
      <v-card-text>
        <div class='stick-scroll'>
          <v-card-title style="margin-left:-15px;color:gray">Product Catalog</v-card-title>
          <v-text-field
            v-model="search"
            label="Search..."
            density="compact"
            hide-details="auto"
            variant="solo"
            class="mb-2"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </div>

        <div class="scroll-content">
          <div v-for="item in catalog_arr" @click="toggleSelectedItem(item.key)" :class="{'selected_item': selected_item_key === item.key, 'unselected_item': selected_item_key !== item.key}" style="padding:2px;margin-bottom:4px;border-radius:2px">
            <div style="padding:4px;line-height:100%">
              <div style="font-size:14px;font-weight:bold;">{{item.description}}</div>
              <div style="font-size:12px;">Product Lines: {{item.product_lines}}</div>
              <div style="font-size:12px;">Riverhawk Part #: {{item.riverhawk_part_number}}</div>
              <div style="font-size:12px;background:#ff7214;border:1px solid white; border-radius:5px;color:white;font-weight:bold;padding:2px;width:35px;text-align:center" v-if="item.vmi">{{item.vmi ? 'VMI' : ''}}</div>
            </div>
          </div>
        </div>  
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="addItem()" :disabled="!selected_item_key">ADD</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'AddFromCatalogPopup',
  data() {
    return {
      dialog: true,
      search: '',
      selected_item_key: '',
      filteredCatalog: []
    };
  },
  created() {
    this.initializeCatalog();
    this.debouncedSearchCatalog = debounce(this.searchCatalog, 100);
  },
  computed: {
    ...mapGetters(['user', 'db']),
    catalog_arr() {
      return this.filteredCatalog;
    }
  },
  watch: {
    search() {
      this.selected_item_key = ''
      this.debouncedSearchCatalog();
    }
  },
  methods: {
    initializeCatalog() {
      this.filteredCatalog = this.convertCatalogToArray(this.db.catalog);
    },
    convertCatalogToArray(catalog) {
      return Object.entries(catalog).map(([key, value]) => {
        // Set 'vmi_string' depending on the value of 'vmi'
        const vmiString = value.vmi ? 'vmi' : '';

        // Return the new object with the added 'vmi_string' field
        return { ...value, key, vmi_string: vmiString };
      });
    },
    searchCatalog() {
      const searchLower = this.search.toLowerCase();
      if (!searchLower) {
        this.initializeCatalog();
        return;
      }

      const filtered = this.convertCatalogToArray(this.db.catalog).filter(item =>
        item.description.toLowerCase().includes(searchLower) ||
        item.product_lines.toLowerCase().includes(searchLower) ||
        item.riverhawk_part_number.toLowerCase().includes(searchLower) ||
        item.vmi_string.toLowerCase().includes(searchLower)  // Include the new field in search
      );
      this.filteredCatalog = filtered;
    },
    toggleSelectedItem(key) {
      this.selected_item_key = this.selected_item_key === key ? '' : key;
    },
    addItem() {
      var item = this.db.catalog[this.selected_item_key]
      this.$emit('add',item);
    }
  }
}
</script>

<style>
  .unselected_item {
    color: gray;
    border: 1px solid gray;
    cursor: pointer;
  }

  .selected_item {
    background: rgba(255, 114, 20, 0.75);
    color: white;
    border: 1px solid rgba(255, 114, 20, 0.75);
    cursor: pointer;
  }

.stick-scroll {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white; /* or the background of the card */
}

.scroll-content {
  height: 65vh;
  overflow-y: auto;
}

</style>
