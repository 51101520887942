<template>
  <div class="reqs">

    <v-card max-width="90%" class="mx-auto mt-5 pl-5 pr-5 pb-5">
      <v-card-item title="Requisitions">
        <template v-slot:subtitle></template>
      </v-card-item>
      <div class="table-container">
          <Vue3EasyDataTable
            :headers="headers"
            :items="reqs_table_data"
            :search-value="searchValue"
            :sort-by="sortBy"
            :sort-type="sortType"
            :table-height="table_height"
            :rows-per-page="250"
            :rows-items="[250, 500, 1000, 5000]"
            :hide-rows-per-page="false"
            :hide-footer="false"
          >

          <template #item-uid="item">
            <router-link :to="{ name: 'req', params: {reqid: item.uid}}" style="color:#ff7214">{{item.uid}}</router-link>
          </template>

        </Vue3EasyDataTable>

        <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:8px;" @click="exportReqs()">EXPORT</v-btn>
      </div>      
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import XLSX from 'xlsx'
import Moment from 'moment'

export default {
  name: 'ReqsView',
  components: {Vue3EasyDataTable},
  data(){
    return {
      searchValue: '',
      sortBy: 'name',
      sortType: 'asc',
      table_height: 400,
      headers: [
        { text: "Req ID", value: "uid", sortable: true },
        { text: "Job Number", value: "job_number", sortable: true },
        { text: "Ship To Address", value: "ship_to_address", sortable: true },
        { text: "Date Needed", value: "date_needed", sortable: true },
        { text: "Status", value: "status", sortable: true }
      ]
    }    
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },  
  computed: {
    ...mapGetters({
      user: 'user',
      db: 'db'
    }),
    reqs_table_data(){
      var arr = []
      var reqs = JSON.parse(JSON.stringify(this.db.reqs))
      for(var k in reqs){
        reqs[k].uid = k
        arr.push(reqs[k])
      }
      return arr
    }
  },
  watch: {

  },
  methods:{
    exportReqs(){
        var items = this.reqs_table_data
        var export_arr = []

        for(var i = 0; i < items.length; i++){
          var item = items[i]
          var items_str = ''
          for(var y = 0; y < item.items.length; y++){
            var str = item.items[y].qty + ' ' + item.items[y].uom + ' ' + item.items[y].description + ' '
            items_str += str
          }
          var row = {
            'Req ID': item.uid,
            'Job Number': item.job_number,
            'Date Items Needed': item.date_needed,
            'Items': items_str,
            'Submitted By Email': item.email,
            'Submitted By Name': item.name,
            'Submitted By Phone': item.phone,
            'Ship To Address': item.ship_to_address,
            'Status': item.status,
            'Created': Moment(item.date_created).format('MM/DD/YYYY hh:mm a'),
            'Modified': Moment(item.date_created).format('MM/DD/YYYY hh:mm a'),
          }
          export_arr.push(row)
        }

        var wscols = [
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30},
            {wch:30}
        ];

        var worksheet = XLSX.utils.json_to_sheet(export_arr)
        worksheet['!cols'] = wscols;
        var workbook = XLSX.utils.book_new()
        var export_date = Date.now()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, 'Requisitions ' + export_date + '.xlsx')
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 260
    }
  }
}
</script>