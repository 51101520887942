import { createStore } from 'vuex'
import firebase from 'firebase/compat/app';

export default createStore({
  state: {
    version: '0.5',
    user: null,
    isLoading: false,
    db: {
      reqs: {},
      catalog: {},
      catalog_last_updated: null
    }
  },
  getters: {
    version: (state) => state.version,
    user: (state) => state.user,
    db: (state) => state.db,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    loadDatabase(state, uid) {
      var self = this
      state.user = uid
      firebase.database().ref('reqs').on('value', function(snapshot) {
        state.db.reqs = snapshot.val();
      });
      firebase.database().ref('catalog').on('value', function(snapshot) {
        state.db.catalog = snapshot.val();
      });
      firebase.database().ref('catalog_last_updated').on('value', function(snapshot) {
        state.db.catalog_last_updated = snapshot.val();
      });      
    }
  },
  actions: {
    loadDatabase: ({ commit }, uid) => commit('loadDatabase', uid),
  },
  modules: {
  }
})
