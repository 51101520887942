<template>
  <v-app>

        <v-app-bar color="gray" density="compact">
          <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-app-bar-title><v-img :width="135" src="./assets/logo_vertical.svg"></v-img></v-app-bar-title>
          <div style="position:absolute;top:30px;left:116px;font-weight:bold;font-size:12px">Requisitions beta v{{version}}</div>
        </v-app-bar>

        <v-navigation-drawer disable-resize-watcher permanent v-model="drawer" color="gray">

          <v-divider></v-divider>

          <v-list density="compact" nav>
            <template v-for="item in items">
              <v-list-item :prepend-icon="item.icon" :title="item.title" :value="item.title" v-if="!item.subitems" @click="navigateTo(item.route)"></v-list-item>
            </template>
          </v-list>

        </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :background-color="'#000'" :opacity="0.8" :color="'#ff7214'" :lock-scroll="true" :loader="'dots'" :blur="'0px'"></loading>    
  </v-app>
</template>

<script>

import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'App',
  components: {Loading},
  data: () => ({
      drawer: false,
      items: [
        {
          title: 'CREATE NEW REQ',
          icon: 'mdi-file-document-edit-outline',
          route: '/req/new'
        },
        {
          title: 'VIEW REQS',
          icon: 'mdi-file-check-outline',
          route: '/reqs'
        },
        {
          title: 'CATALOG',
          icon: 'mdi-book-open-page-variant-outline',
          route: '/catalog'
        },             
      ]
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      db: 'db',
      version: 'version',
      isLoading: 'isLoading'
    }),
  },
  mounted(){
    this.loadDatabase('test-user')
  },
  methods:{
    ...mapActions(['loadDatabase']),
    navigateTo(route) {
      this.drawer = false;
      this.$router.push(route);
    }
  }  
}
</script>

<style>
body {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.v-textarea textarea {
      line-height: 100%;
 }
</style>