<template>
  <div class="catalog">
    
    <v-file-input 
      label="Select Excel Catalog File" 
      hide-details="true" 
      v-model="selectedFile" 
      @change="handleFileUpload" 
      ref="fileInput"
      style="display: none;">
    </v-file-input>

    <v-card max-width="90%" class="mx-auto mt-5 pl-5 pr-5 pb-2">

      <v-text-field label="Search Catalog..." variant="solo" density="compact" hide-details="true" @input="debouncedSearch($event)" class="mt-2 mb-2"></v-text-field>
          
      <Vue3EasyDataTable
        :headers="headers"
        :items="catalog_table_data"
        :search-value="searchValue"
        :sort-by="sortBy"
        :sort-type="sortType"
        :table-height="table_height"
        :rows-per-page="50000"
        :rows-items="[250, 500, 1000, 5000]"
        :hide-rows-per-page="true"
        :hide-footer="false"
      >
    </Vue3EasyDataTable>

      <v-btn 
        color="orange"
        size="x-small"
        class="mt-2"
        @click="triggerFileUpload">
        UPLOAD NEW CATALOG FILE
      </v-btn>

      <div style="color:gray;font-size:10px;padding-top:5px">Last Uploaded {{last_updated_formated}}</div>

    </v-card>

  </div>
</template>


<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import * as XLSX from "xlsx"
import Vue3EasyDataTable from 'vue3-easy-data-table';
import Moment from 'moment'
import { debounce } from 'lodash';
export default {
  name: 'CatalogView',
  components: {Vue3EasyDataTable},
   data(){
    return {
      selectedFile: null,
      uploadedCatalogData: [],
      searchValue: '',
      sortBy: 'description',
      sortType: 'asc',
      table_height: 400,
      headers: [
        { text: "Description", value: "description", sortable: true },
        { text: "Product Lines", value: "product_lines", sortable: true },
        { text: "Riverhawk Part Number", value: "riverhawk_part_number", sortable: true },
        { text: "VMI", value: "vmi", sortable: true }
      ]      
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      db: 'db'
    }),
    last_updated_formated(){
      return Moment(this.db.catalog_last_updated).format('MM/DD/YYYY h:mma')
    },
    catalog_table_data(){
      var arr = []
      var items = JSON.parse(JSON.stringify(this.db.catalog))

      for(var i in items){
        if(items[i]){
          items[i].index = i
          items[i].vmi = items[i].vmi == true ? 'VMI' : ''
          arr.push(items[i])          
        }
      }
      return arr
    }
  },  
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },  
  methods:{
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value.replace(/\$/g, '')
    }, 500),    
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 300
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },    
    handleFileUpload(event) {
      this.$store.state.isLoading = true
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = 'catalog'; // The name of the sheet to be read
        const worksheet = workbook.Sheets[sheetName];
        if (worksheet) {
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          this.uploadedCatalogData = jsonData.map(item => ({
            riverhawk_part_number: item.riverhawk_part_number,
            description: item.description,
            product_lines: item.product_lines,
            vmi: item.vmi === 'Yes VMI' ? true : false
          }));
          this.loadDatabase()
        } else {
          this.selectedFile = null;
          this.$store.state.isLoading = false          
          alert('Incorrect file.')
        }
      };
      reader.readAsBinaryString(file);
    },
    loadDatabase(){
      var self = this;
      firebase.database().ref('catalog').set(self.uploadedCatalogData).then(function(){
        self.selectedFile = null; // Reset the file input
        firebase.database().ref('catalog_last_updated').set(Date.now())
        setTimeout(function(){
          self.$store.state.isLoading = false
        },2000)
      });
    }
  }
}
</script>
