import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'vue3-easy-data-table/dist/style.css';

firebase.initializeApp(
  {
  apiKey: "AIzaSyDiHXckAr5W2lJbsvJJJ0dANVybn0B0F8k",
  authDomain: "riverhawk-req.firebaseapp.com",
  databaseURL: "https://riverhawk-req-default-rtdb.firebaseio.com",
  projectId: "riverhawk-req",
  storageBucket: "riverhawk-req.appspot.com",
  messagingSenderId: "970125662114",
  appId: "1:970125662114:web:51cf6843ef54cea9e6c4fb"
  }
);

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
